<template>
  <div class="market-minimum-sidebar">
    <v-form ref="marketMinimumGroup">
      <v-container class="market-minimum-sidebar--minimum-form" grid-list-md>
        <v-layout wrap>
          <v-flex x12>
            <CRInput
              id="market-minimum-name"
              v-model="form.name"
              label="Name"
              placeholder="Minimum Name"
              :disabled="isModeView"
              type="text"
              :rules="[
                isRequired(true, isNotEmpty, {
                  req: 'Minimum Name is Required',
                  error: 'Minimum Name is Required',
                }),
              ]"
            />
          </v-flex>
          <v-flex xs12>
            <CRSelect
              :id="`select-market`"
              v-model="form.markets"
              flat
              label="Market"
              placeholder="Select Market"
              :disabled="isModeView"
              :multiple="isModeAdd ? true : false"
              :items="markets"
              item-text="marketName"
              item-value="marketId"
              :menu-props="{ overflowY: true, maxHeight: '200px !important' }"
            />
          </v-flex>
          <v-flex xs6>
            <CRInput
              id="market-minimum-form-start-date"
              v-model="form.startDate"
              :disabled="isModeView"
              label="Start Date"
              type="date"
              :rules="[
                isRequired(true, isNotEmpty, {
                  req: 'Start Date is Required',
                  error: 'Start Date is Required',
                }),
              ]"
              @change="
                (date) => {
                  if (!form.endDate) {
                    form.endDate = getOffsetDate(date, 1)
                  }
                }
              "
            />
          </v-flex>
          <v-flex xs6>
            <CRInput
              id="market-minimum-form-expiration-date"
              v-model="form.endDate"
              :disabled="isModeView"
              label="End Date"
              type="date"
              :rules="[
                isRequired(true, isNotEmpty, {
                  req: 'End Date is Required',
                  error: 'End Date is Required',
                }),
                (val) =>
                  val < form.startDate
                    ? 'Start Date must not be after End Date'
                    : true,
              ]"
              @change="
                (date) => {
                  if (!form.startDate) {
                    form.startDate = getOffsetDate(date, -1)
                  }
                }
              "
            />
          </v-flex>
          <v-flex xs12>
            <CRSelect
              id="market-minimum-form-day"
              v-model="form.days"
              class="market-minimum-sidebar--minimum-base"
              :disabled="isModeView"
              multiple
              label="Day"
              placeholder="Select day"
              item-text="description"
              item-value="day"
              :items="days"
            />
          </v-flex>
          <template v-if="loading">
            <v-layout
              column
              justify-space-around
              style="background-color: white; height: 300px"
            >
              <v-progress-circular
                style="margin: 0 auto"
                :size="70"
                indeterminate
                color="primary"
              />
            </v-layout>
          </template>
          <template v-else>
            <v-flex xs12>
              <CRSelect
                id="market-minimum-form-vehicle-type"
                v-model="form.vehicleTypes"
                :disabled="isModeEdit || isModeView"
                multiple
                label="Vehicle Type"
                placeholder="Select vehicle type"
                item-value="key"
                item-text="label"
                :items="vehicleTypes"
                :rules="[
                  isRequired(true, isNotEmpty, {
                    req: 'Vehicle Type is Required',
                    error: 'Vehicle Type is Required',
                  }),
                ]"
              />
            </v-flex>
            <v-flex xs6>
              <CRInput
                :id="`market-minimum-form-daily-rate`"
                v-model="form.dailyRate"
                :disabled="isModeView"
                label="Daily Rate"
                type="number"
                prefix="$"
                :rules="[
                  isRequired(
                    true,
                    () => validateGreaterThanZero(form.dailyRate),
                    {
                      req: 'Daily Rate Is Required',
                      error: 'Daily Rate Is Required',
                    }
                  ),
                ]"
              />
            </v-flex>
            <v-flex xs6>
              <CRInput
                id="market-minimum-form-daily-quantity"
                v-model="form.dailyQuantity"
                :disabled="isModeView"
                label="Daily Quantity"
                placeholder="Daily Quantity"
                type="number"
                :rules="[
                  isRequired(
                    true,
                    () => validateGreaterThanZero(form.dailyQuantity),
                    {
                      req: 'Daily Quantity is Required',
                      error: 'Daily Quantity is Required',
                    }
                  ),
                ]"
              />
            </v-flex>
            <v-flex xs6>
              <CRInput
                id="market-minimum-form-hourly-rate"
                v-model="form.hourlyRate"
                :disabled="isModeView"
                label="Hourly Rate"
                type="number"
                prefix="$"
                :rules="[
                  isRequired(
                    true,
                    () => validateGreaterThanZero(form.hourlyRate),
                    {
                      req: 'Hourly Rate is Required',
                      error: 'Hourly Rate is Required',
                    }
                  ),
                ]"
              />
            </v-flex>
            <v-flex xs6>
              <CRInput
                id="market-minimum-form-hourly-quantity"
                v-model="form.hourlyQuantity"
                :disabled="isModeView"
                label="Hourly Quantity"
                placeholder="Hourly Quantity"
                type="number"
                :rules="[
                  isRequired(
                    true,
                    () => validateGreaterThanZero(form.hourlyQuantity),
                    {
                      req: 'Hourly Quantity is Required',
                      error: 'Hourly Quantity is Required',
                    }
                  ),
                ]"
              />
            </v-flex>
            <v-flex xs6>
              <CRInput
                id="market-minimum-form-mileage-rate"
                v-model="form.mileageRate"
                :disabled="isModeView"
                label="Mileage Rate"
                type="number"
                prefix="$"
                :rules="[
                  isRequired(
                    true,
                    () => validateGreaterThanZero(form.mileageRate),
                    {
                      req: 'Mileage Rate is Required',
                      error: 'Mileage Rate is Required',
                    }
                  ),
                ]"
              />
            </v-flex>
            <v-flex xs6>
              <CRInput
                id="market-minimum-form-mileage-quantity"
                v-model="form.mileageQuantity"
                :disabled="isModeView"
                label="Mileage Quantity"
                placeholder="Mileage Quantity"
                type="number"
                :rules="[
                  isRequired(
                    true,
                    () => validateGreaterThanZero(form.mileageQuantity),
                    {
                      req: 'Mileage Quantity is Required',
                      error: 'Mileage Quantity is Required',
                    }
                  ),
                ]"
              />
            </v-flex>
            <v-flex xs12>
              <table class="market-minimum-sidebar--minimum-table">
                <tr>
                  <td>Daily:</td>
                  <td>
                    {{ dailyMinimum }}
                  </td>
                </tr>
                <tr>
                  <td>Hourly:</td>
                  <td>
                    {{ hourlyMinimum }}
                  </td>
                </tr>
                <tr>
                  <td>Mileage:</td>
                  <td>
                    {{ mileageMinimum }}
                  </td>
                </tr>
              </table>
            </v-flex>
            <v-flex xs12 style="text-align: center">
              Minimum bids per pricing method
            </v-flex>
          </template>
        </v-layout>
      </v-container>
      <div class="cr-sidebar-dialog--button-spacer"></div>
      <div v-if="isModeAdd">
        <CRButton
          id="market-minimum-sidebar-save-btn"
          :disabled="loading"
          class="market-minimum-sidebar--action-btn"
          color="primary"
          @click="addHandler"
        >
          Save
        </CRButton>
      </div>
      <div v-else-if="isModeView">
        <CRButton
          id="market-minimum-sidebar-edit-btn"
          :disabled="loading"
          class="market-minimum-sidebar--action-btn"
          color="primary"
          @click="editHandler"
        >
          Edit
        </CRButton>
      </div>
      <div v-else-if="isModeEdit">
        <div class="cr-sidebar-dialog--button-spacer"></div>
        <CRButton
          id="market-minimum-sidebar-delete-btn"
          :disabled="loading"
          class="market-minimum-sidebar--delete-btn"
          text-color="red"
          @click="deleteHandler"
        >
          Delete
        </CRButton>
        <CRButton
          id="market-minimum-sidebar-save-btn"
          :disabled="loading"
          class="market-minimum-sidebar--action-btn"
          color="primary"
          @click="saveHandler"
        >
          Save
        </CRButton>
      </div>
    </v-form>
  </div>
</template>

<script>
import markets from '@/services/markets'
import minimum from '@/services/minimum'
import {
  isRequired,
  isNotEmpty,
  validateGreaterThanZero,
} from '@/utils/validators'
import { getOffsetDate, daysMap } from '@/utils/time'
import { currencyFilter } from '@/utils/currency'
import { EventBus } from '@/utils/event-bus'
import { authComputed } from '@/state/helpers'

export default {
  props: {
    id: {
      type: Number,
      default: null,
    },
    row: {
      type: Object,
      default: () => {},
    },
    mode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      days: daysMap,
      form: {
        name: null,
        markets: null,
        startDate: null,
        endDate: null,
        days: [],
        vehicleTypes: null,
        dailyRate: null,
        dailyQuantity: null,
        hourlyRate: null,
        hourlyQuantity: null,
        mileageRate: null,
        mileageQuantity: null,
      },
      internalMode: null,
      loading: false,
      markets: [],
      minimums: [],
      vehicleTypes: [],
    }
  },
  computed: {
    ...authComputed,
    isModeAdd() {
      return this.internalMode === 'add'
    },
    isModeView() {
      return this.internalMode === 'view'
    },
    isModeEdit() {
      return this.internalMode === 'edit'
    },
    dailyMinimum() {
      return (
        currencyFilter(this.form.dailyRate * this.form.dailyQuantity) || null
      )
    },
    hourlyMinimum() {
      return (
        currencyFilter(this.form.hourlyRate * this.form.hourlyQuantity) || null
      )
    },
    mileageMinimum() {
      return (
        currencyFilter(this.form.mileageRate * this.form.mileageQuantity) ||
        null
      )
    },
    marketMinimumDays() {
      return this.form.days.map((day) => ({
        day: day,
      }))
    },
  },
  async mounted() {
    this.loading = true
    this.getMarkets()
    this.getVehicleTypes()
    this.internalMode = this.mode
    if (this.isModeView) {
      await this.getMinimumGroup()
    }
    this.loading = false
  },
  methods: {
    isRequired,
    isNotEmpty,
    validateGreaterThanZero,
    getOffsetDate,
    async getMarkets() {
      this.markets = await markets
        .tableView({
          pageSize: -1,
        })
        .then((data) => data.data.resultList)
    },
    async getVehicleTypes() {
      this.vehicleTypes = await this.$store
        .dispatch('types/getVehicleTypes')
        .then((data) => data.data.resultList)
    },
    async getMinimumGroup() {
      this.form.name = this.row?.marketMinimumGroupName
      this.form.markets = this.row?.marketId
      this.form.startDate = this.row?.startDate
      this.form.endDate = this.row?.endDate

      let dayString
      if (this.row?.days) {
        dayString = this.row?.days?.replace(' ', '')
      }
      if (dayString) {
        this.form.days = dayString?.split(',')?.map((day) => parseInt(day))
      }

      this.minimums = await minimum
        .getGroup(this.id)
        .then((res) => res?.data?.marketMinimumGroup?.marketMinimums)
      let vehicleTypes = [
        ...new Set(this.minimums.map((minimum) => minimum.vehicleTypeKey)),
      ]
      this.form.vehicleTypes = vehicleTypes

      let dailyMinimum = this.minimums.find(
        (minimum) => minimum.marketRateTypeKey === 'daily_rate'
      )
      this.form.dailyRate = dailyMinimum?.rateMinimum
      this.form.dailyQuantity = dailyMinimum?.quantityMinimum
      this.dailyMinimumId = dailyMinimum?.marketMinimumId

      let hourlyMinimum = this.minimums.find(
        (minimum) => minimum.marketRateTypeKey === 'hourly_rate'
      )
      this.form.hourlyRate = hourlyMinimum?.rateMinimum
      this.form.hourlyQuantity = hourlyMinimum?.quantityMinimum
      this.hourlyMinimumId = hourlyMinimum?.marketMinimumId

      let mileageMinimum = this.minimums.find(
        (minimum) => minimum.marketRateTypeKey === 'mileage'
      )
      this.form.mileageRate = mileageMinimum?.rateMinimum
      this.form.mileageQuantity = mileageMinimum?.quantityMinimum
      this.mileageMinimumId = mileageMinimum?.marketMinimumId
    },
    createPayload(hasId, companyId, minimums, marketId) {
      const payload = {
        marketMinimumGroupName: this.form.name,
        companyId: companyId,
        marketId: marketId,
        days: this.form.days.sort().join(', '),
        startDate: this.form.startDate,
        endDate: this.form.endDate,
        marketMinimums: minimums,
      }
      if (hasId) {
        payload.marketMinimumGroupId = this.id
      }
      return payload
    },
    createNewMinimumObjects(marketId) {
      const newMinimums = this.form.vehicleTypes.flatMap((vehicleType) => {
        const newMinimum = {
          companyId: this.currentUser.companyId,
          marketId: marketId,
          vehicleTypeKey: vehicleType,
          startDate: this.form.startDate,
          endDate: this.form.endDate,
          marketMinimumDays: this.marketMinimumDays,
        }
        const newDailyMinimum = {
          ...newMinimum,
          marketRateTypeKey: 'daily_rate',
          rateMinimum: this.form.dailyRate,
          quantityMinimum: this.form.dailyQuantity,
        }
        const newHourlyMinimum = {
          ...newMinimum,
          marketRateTypeKey: 'hourly_rate',
          rateMinimum: this.form.hourlyRate,
          quantityMinimum: this.form.hourlyQuantity,
        }
        const newMileageMinimum = {
          ...newMinimum,
          marketRateTypeKey: 'mileage',
          rateMinimum: this.form.mileageRate,
          quantityMinimum: this.form.mileageQuantity,
        }
        return [newDailyMinimum, newHourlyMinimum, newMileageMinimum]
      })
      return newMinimums
    },
    async addHandler(event) {
      event.preventDefault()
      if (!this.$refs.marketMinimumGroup.validate()) {
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message: 'Missing Fields',
        })
        return
      }

      const payload = this.form.markets.map((market) => {
        return this.createPayload(
          false,
          this.currentUser.companyId,
          this.createNewMinimumObjects(market),
          market
        )
      })

      try {
        await minimum.createGroups({
          payload,
        })

        this.alertSuccess('add')
        EventBus.$emit(
          'reload-pricing-calendar',
          this.form.startDate,
          this.form.endDate,
          'minimumGroup',
          this.id
        )
        this.closeSidebar()
      } catch (e) {
        this.alertFail('add')
      }
    },
    editHandler(event) {
      event.preventDefault()
      this.internalMode = 'edit'
    },
    async saveHandler(event) {
      event.preventDefault()
      if (!this.$refs.marketMinimumGroup.validate()) {
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message: 'Missing Fields',
        })
        return
      }

      const updatedMinimums = this.minimums.map((minimum) => {
        const updatedMinimum = {
          marketMinimumId: minimum.marketMinimumId,
          marketId: this.form.markets,
          vehicleTypeKey: minimum.vehicleTypeKey,
          startDate: this.form.startDate,
          endDate: this.form.endDate,
          marketMinimumDays: this.marketMinimumDays,
        }
        switch (minimum.marketRateTypeKey) {
          case 'daily_rate':
            return {
              ...updatedMinimum,
              rateMinimum: this.form.dailyRate,
              quantityMinimum: this.form.dailyQuantity,
            }
          case 'hourly_rate':
            return {
              ...updatedMinimum,
              rateMinimum: this.form.hourlyRate,
              quantityMinimum: this.form.hourlyQuantity,
            }
          case 'mileage':
            return {
              ...updatedMinimum,
              rateMinimum: this.form.mileageRate,
              quantityMinimum: this.form.mileageQuantity,
            }
        }
      })

      const payload = this.createPayload(
        true,
        this.row?.companyId,
        updatedMinimums,
        this.form.markets
      )

      try {
        await minimum.updateGroup({
          payload,
        })

        this.alertSuccess('update')
        EventBus.$emit(
          'reload-pricing-calendar',
          this.form.startDate,
          this.form.endDate,
          'minimumGroup',
          this.id
        )
        this.closeSidebar()
      } catch (e) {
        this.alertFail('update')
      }
    },
    async deleteHandler(event) {
      event.preventDefault()
      try {
        await minimum.deleteGroup(this.id)
        this.alertSuccess('delete')
        EventBus.$emit(
          'reload-pricing-calendar',
          this.form.startDate,
          this.form.endDate,
          'minimumGroup',
          this.id
        )
        this.closeSidebar()
      } catch (e) {
        this.alertFail('delete')
      }
    },
    alertSuccess(event) {
      let action
      switch (event) {
        case 'add':
          action = 'added'
          break
        case 'update':
          action = 'updated'
          break
        case 'delete':
          action = 'deleted'
          break
      }
      this.$store.dispatch('app/showAlert', {
        message: `Successfully ${action} minimum.`,
      })
    },
    alertFail(event) {
      let action
      switch (event) {
        case 'add':
          action = 'add'
          break
        case 'update':
          action = 'update'
          break
        case 'delete':
          action = 'delete'
          break
      }
      this.$store.dispatch('app/showAlert', {
        type: 'error',
        message: `Unable to ${action} minimum.`,
      })
    },
    closeSidebar() {
      EventBus.$emit('market-minimum-tv-refresh')
      this.$store.dispatch('app/closeSidebarDialog')
    },
  },
}
</script>
<style lang="scss" scoped>
.market-minimum-sidebar {
  display: flex;
  height: 100%;
  width: 500px;

  &--back-arrow {
    a {
      display: flex;
      align-items: center;

      .cr-icon {
        margin-left: 4px;
        margin-right: 18px !important;
      }
    }
    margin-top: -10px;
    margin-bottom: 15px;
  }

  &--minimum-form {
    margin: 0 !important;
    padding: 15px 40px;
  }

  &--minimum-base {
    border-bottom: 1px solid $dispatch-gray;
    margin-bottom: 20px;
  }

  &--button-spacer {
    min-height: 71px;
    width: 100%;
  }

  &--delete-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 71px;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    border-radius: 0;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 0;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    border-radius: 0;
  }

  &--minimum-table {
    margin: auto;
    width: 50%;
    font-weight: bold;
  }
}
</style>
