var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"market-minimum-sidebar"},[_c('v-form',{ref:"marketMinimumGroup"},[_c('v-container',{staticClass:"market-minimum-sidebar--minimum-form",attrs:{"grid-list-md":""}},[_c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{attrs:{"x12":""}},[_c('CRInput',{attrs:{"id":"market-minimum-name","label":"Name","placeholder":"Minimum Name","disabled":_vm.isModeView,"type":"text","rules":[
              _vm.isRequired(true, _vm.isNotEmpty, {
                req: 'Minimum Name is Required',
                error: 'Minimum Name is Required',
              }),
            ]},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('CRSelect',{attrs:{"id":`select-market`,"flat":"","label":"Market","placeholder":"Select Market","disabled":_vm.isModeView,"multiple":_vm.isModeAdd ? true : false,"items":_vm.markets,"item-text":"marketName","item-value":"marketId","menu-props":{ overflowY: true, maxHeight: '200px !important' }},model:{value:(_vm.form.markets),callback:function ($$v) {_vm.$set(_vm.form, "markets", $$v)},expression:"form.markets"}})],1),_c('v-flex',{attrs:{"xs6":""}},[_c('CRInput',{attrs:{"id":"market-minimum-form-start-date","disabled":_vm.isModeView,"label":"Start Date","type":"date","rules":[
              _vm.isRequired(true, _vm.isNotEmpty, {
                req: 'Start Date is Required',
                error: 'Start Date is Required',
              }),
            ]},on:{"change":(date) => {
                if (!_vm.form.endDate) {
                  _vm.form.endDate = _vm.getOffsetDate(date, 1)
                }
              }},model:{value:(_vm.form.startDate),callback:function ($$v) {_vm.$set(_vm.form, "startDate", $$v)},expression:"form.startDate"}})],1),_c('v-flex',{attrs:{"xs6":""}},[_c('CRInput',{attrs:{"id":"market-minimum-form-expiration-date","disabled":_vm.isModeView,"label":"End Date","type":"date","rules":[
              _vm.isRequired(true, _vm.isNotEmpty, {
                req: 'End Date is Required',
                error: 'End Date is Required',
              }),
              (val) =>
                val < _vm.form.startDate
                  ? 'Start Date must not be after End Date'
                  : true,
            ]},on:{"change":(date) => {
                if (!_vm.form.startDate) {
                  _vm.form.startDate = _vm.getOffsetDate(date, -1)
                }
              }},model:{value:(_vm.form.endDate),callback:function ($$v) {_vm.$set(_vm.form, "endDate", $$v)},expression:"form.endDate"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('CRSelect',{staticClass:"market-minimum-sidebar--minimum-base",attrs:{"id":"market-minimum-form-day","disabled":_vm.isModeView,"multiple":"","label":"Day","placeholder":"Select day","item-text":"description","item-value":"day","items":_vm.days},model:{value:(_vm.form.days),callback:function ($$v) {_vm.$set(_vm.form, "days", $$v)},expression:"form.days"}})],1),(_vm.loading)?[_c('v-layout',{staticStyle:{"background-color":"white","height":"300px"},attrs:{"column":"","justify-space-around":""}},[_c('v-progress-circular',{staticStyle:{"margin":"0 auto"},attrs:{"size":70,"indeterminate":"","color":"primary"}})],1)]:[_c('v-flex',{attrs:{"xs12":""}},[_c('CRSelect',{attrs:{"id":"market-minimum-form-vehicle-type","disabled":_vm.isModeEdit || _vm.isModeView,"multiple":"","label":"Vehicle Type","placeholder":"Select vehicle type","item-value":"key","item-text":"label","items":_vm.vehicleTypes,"rules":[
                _vm.isRequired(true, _vm.isNotEmpty, {
                  req: 'Vehicle Type is Required',
                  error: 'Vehicle Type is Required',
                }),
              ]},model:{value:(_vm.form.vehicleTypes),callback:function ($$v) {_vm.$set(_vm.form, "vehicleTypes", $$v)},expression:"form.vehicleTypes"}})],1),_c('v-flex',{attrs:{"xs6":""}},[_c('CRInput',{attrs:{"id":`market-minimum-form-daily-rate`,"disabled":_vm.isModeView,"label":"Daily Rate","type":"number","prefix":"$","rules":[
                _vm.isRequired(
                  true,
                  () => _vm.validateGreaterThanZero(_vm.form.dailyRate),
                  {
                    req: 'Daily Rate Is Required',
                    error: 'Daily Rate Is Required',
                  }
                ),
              ]},model:{value:(_vm.form.dailyRate),callback:function ($$v) {_vm.$set(_vm.form, "dailyRate", $$v)},expression:"form.dailyRate"}})],1),_c('v-flex',{attrs:{"xs6":""}},[_c('CRInput',{attrs:{"id":"market-minimum-form-daily-quantity","disabled":_vm.isModeView,"label":"Daily Quantity","placeholder":"Daily Quantity","type":"number","rules":[
                _vm.isRequired(
                  true,
                  () => _vm.validateGreaterThanZero(_vm.form.dailyQuantity),
                  {
                    req: 'Daily Quantity is Required',
                    error: 'Daily Quantity is Required',
                  }
                ),
              ]},model:{value:(_vm.form.dailyQuantity),callback:function ($$v) {_vm.$set(_vm.form, "dailyQuantity", $$v)},expression:"form.dailyQuantity"}})],1),_c('v-flex',{attrs:{"xs6":""}},[_c('CRInput',{attrs:{"id":"market-minimum-form-hourly-rate","disabled":_vm.isModeView,"label":"Hourly Rate","type":"number","prefix":"$","rules":[
                _vm.isRequired(
                  true,
                  () => _vm.validateGreaterThanZero(_vm.form.hourlyRate),
                  {
                    req: 'Hourly Rate is Required',
                    error: 'Hourly Rate is Required',
                  }
                ),
              ]},model:{value:(_vm.form.hourlyRate),callback:function ($$v) {_vm.$set(_vm.form, "hourlyRate", $$v)},expression:"form.hourlyRate"}})],1),_c('v-flex',{attrs:{"xs6":""}},[_c('CRInput',{attrs:{"id":"market-minimum-form-hourly-quantity","disabled":_vm.isModeView,"label":"Hourly Quantity","placeholder":"Hourly Quantity","type":"number","rules":[
                _vm.isRequired(
                  true,
                  () => _vm.validateGreaterThanZero(_vm.form.hourlyQuantity),
                  {
                    req: 'Hourly Quantity is Required',
                    error: 'Hourly Quantity is Required',
                  }
                ),
              ]},model:{value:(_vm.form.hourlyQuantity),callback:function ($$v) {_vm.$set(_vm.form, "hourlyQuantity", $$v)},expression:"form.hourlyQuantity"}})],1),_c('v-flex',{attrs:{"xs6":""}},[_c('CRInput',{attrs:{"id":"market-minimum-form-mileage-rate","disabled":_vm.isModeView,"label":"Mileage Rate","type":"number","prefix":"$","rules":[
                _vm.isRequired(
                  true,
                  () => _vm.validateGreaterThanZero(_vm.form.mileageRate),
                  {
                    req: 'Mileage Rate is Required',
                    error: 'Mileage Rate is Required',
                  }
                ),
              ]},model:{value:(_vm.form.mileageRate),callback:function ($$v) {_vm.$set(_vm.form, "mileageRate", $$v)},expression:"form.mileageRate"}})],1),_c('v-flex',{attrs:{"xs6":""}},[_c('CRInput',{attrs:{"id":"market-minimum-form-mileage-quantity","disabled":_vm.isModeView,"label":"Mileage Quantity","placeholder":"Mileage Quantity","type":"number","rules":[
                _vm.isRequired(
                  true,
                  () => _vm.validateGreaterThanZero(_vm.form.mileageQuantity),
                  {
                    req: 'Mileage Quantity is Required',
                    error: 'Mileage Quantity is Required',
                  }
                ),
              ]},model:{value:(_vm.form.mileageQuantity),callback:function ($$v) {_vm.$set(_vm.form, "mileageQuantity", $$v)},expression:"form.mileageQuantity"}})],1),_c('v-flex',{attrs:{"xs12":""}},[_c('table',{staticClass:"market-minimum-sidebar--minimum-table"},[_c('tr',[_c('td',[_vm._v("Daily:")]),_c('td',[_vm._v(" "+_vm._s(_vm.dailyMinimum)+" ")])]),_c('tr',[_c('td',[_vm._v("Hourly:")]),_c('td',[_vm._v(" "+_vm._s(_vm.hourlyMinimum)+" ")])]),_c('tr',[_c('td',[_vm._v("Mileage:")]),_c('td',[_vm._v(" "+_vm._s(_vm.mileageMinimum)+" ")])])])]),_c('v-flex',{staticStyle:{"text-align":"center"},attrs:{"xs12":""}},[_vm._v(" Minimum bids per pricing method ")])]],2)],1),_c('div',{staticClass:"cr-sidebar-dialog--button-spacer"}),(_vm.isModeAdd)?_c('div',[_c('CRButton',{staticClass:"market-minimum-sidebar--action-btn",attrs:{"id":"market-minimum-sidebar-save-btn","disabled":_vm.loading,"color":"primary"},on:{"click":_vm.addHandler}},[_vm._v(" Save ")])],1):(_vm.isModeView)?_c('div',[_c('CRButton',{staticClass:"market-minimum-sidebar--action-btn",attrs:{"id":"market-minimum-sidebar-edit-btn","disabled":_vm.loading,"color":"primary"},on:{"click":_vm.editHandler}},[_vm._v(" Edit ")])],1):(_vm.isModeEdit)?_c('div',[_c('div',{staticClass:"cr-sidebar-dialog--button-spacer"}),_c('CRButton',{staticClass:"market-minimum-sidebar--delete-btn",attrs:{"id":"market-minimum-sidebar-delete-btn","disabled":_vm.loading,"text-color":"red"},on:{"click":_vm.deleteHandler}},[_vm._v(" Delete ")]),_c('CRButton',{staticClass:"market-minimum-sidebar--action-btn",attrs:{"id":"market-minimum-sidebar-save-btn","disabled":_vm.loading,"color":"primary"},on:{"click":_vm.saveHandler}},[_vm._v(" Save ")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }