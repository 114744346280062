import { render, staticRenderFns } from "./MarketMinimumSidebar.vue?vue&type=template&id=57bc882c&scoped=true"
import script from "./MarketMinimumSidebar.vue?vue&type=script&lang=js"
export * from "./MarketMinimumSidebar.vue?vue&type=script&lang=js"
import style0 from "./MarketMinimumSidebar.vue?vue&type=style&index=0&id=57bc882c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57bc882c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VContainer,VFlex,VForm,VLayout,VProgressCircular})
